import React from 'react'
import ProjectLayout from '../components/ProductPage/ProductPage'
import Footer from '../components/Footer/Footer'
const Products = () => {
  return (
    <div className='flex flex-col'>
      <ProjectLayout/>
      <Footer/>
      
    </div>
  )
}

export default Products