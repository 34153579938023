import image1 from '../../Image/Premium&Economic/Tourism1.png';
import image2 from '../../Image/Premium&Economic/Tourism2.png';
import image3 from '../../Image/Premium&Economic/Tourism3.png';
import image4 from '../../Image/Premium&Economic/Tourism4.png';

const PremiumEconomic = {
    title: 'Premium & Economic Packages',
    description: 'Customize Your Trips According to Your Wish',
    buttonTitle:'Plan Your Trip',
    link: 'https://forms.gle/GWe2vKBf94R3aKdf6',
    content: [
        {
            title: 'Pilgrim Tourism',
            description: 'Visit the world famous pilgrimage, spiritual centres and religious places with your family. We offer a wide range of packages over the world..',
            image: image1,
            link:'#'
        },
        {
            title: 'Care & Joy',
            description: 'Gift a trip for your old age parents.We offer this service only for the senior citizens.We assure 100% safe and genuine service.',
            image: image2,
            link:'#'
        },
        {
            title: 'Ladies Only',
            description: 'Its your turn now. Fly to your dream location with safe wings. We have the  best, affordable, safest tour packages for ladies.',
            image: image3,
            link:'#'
        },
        {
            title: 'Heritage & Historical Tourism',
            description: 'We offer international packages to visit world famous historical places, monuments. Fly to the histories in our wings',
            image: image4,
            link:'#'
        }
    ]
};

export default PremiumEconomic;
