import React from 'react'
import HomeLayout from "../components/Home/Home"
import Footer from '../components/Footer/Footer'
const Home = () => {
  return (
    <div>
      <HomeLayout/>
      
    </div>
  )
}

export default Home