import image1 from '../../Image/Project&Development/Tourism1.png';
import image2 from '../../Image/Project&Development/Tourism2.png';

const ProjectDevelopment = {
    title: 'Projects & Development Services',
    description: 'Be a Part of This Industry',
    buttonTitle:'Let’s Discuss Your Project',
    link: 'https://forms.gle/aUPUJRWxZfnJZCQK6',
    content: [
        {
            title: 'Sight Creation & Tourism Development',
            description: 'Tourism can give you another heights. If you have land we will help you to make it a tourist spot. Tourism Infrastructure Development is a key role in your business. Make it attractive and innovative. The world always runs after the new.',
            image: image1,
            link:'#'
        },
        {
            title: 'Tourism Infrastructure Development',
            description: 'Tourism Infrastructure development involves a crucial role in the tourism industry. This includes ideation, designing, building etc. We offer all architectural & technical solution for this.',
            image: image2,
            link:'#'
        }
    ]
};

export default ProjectDevelopment;
