import image1 from '../../Image/PremiumPackages/Tourism1.png';
import image2 from '../../Image/PremiumPackages/Tourism2.png';
import image3 from '../../Image/PremiumPackages/Tourism3.png';
import image4 from '../../Image/PremiumPackages/Tourism4.png';

const Premium = {
    title: 'Premium Packages',
    description: 'Customize Your Trips According to Your Wish',
    buttonTitle:'Plan Your Trip',
    link: 'https://forms.gle/GWe2vKBf94R3aKdf6',
    content: [
        {
            title: 'Festival & Cultural Tourism',
            description: 'We are providing seasonal packages to experience our festivals and cultures like Pooram, Padayani, Onam, Theyyam, Thira, Holi, Pongal, Navarathri etc. This is a seasonal service as per the festivals.',
            image: image1,
            link:'https://forms.gle/kFZKqt6uZbnnAcDV9'
        },
        {
            title: 'Adventure & Wildlife Tourism',
            description: "A Night in a Jungle is another level of adventure. Then what about paragliding? If it's underwater diving? We will help you to find the best place for your adventure mind",
            image: image2,
            link:'https://forms.gle/EBhZsd2Nhz5YaW8e7'
        },
        {
            title: 'Wellness & Medical Tourism',
            description: 'Always choose the best for your health. Ayurveda, the ancient Indian medical system, helps you to rejuvenate and lead a healthy life',
            image: image3,
            link:'https://forms.gle/MyCyurD97RknDqkaA'
        },
        {
            title: 'Community Based Tourism',
            description: 'Community tourism offers in depth experience of rarest foods, cultures, community based artforms, handicraft making etc. This is purely based on the special communities located in various locations in the country.',
            image: image4,
            link:'https://forms.gle/7pZZczzbLmj5VKG59'
        }
    ]
};

export default Premium;
